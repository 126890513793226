import React, { useState, useEffect } from "react";

import * as queryString from "query-string";

import { Link } from "gatsby";

import { Tabs, Tab } from "react-bootstrap";

import SEO from "components/seo/index";

import DonationGiftingForm from "forms/DonationGiftingForm";

import Layout from "layout/index";

// service
import DonationService from "services/donations/donation.service";

// schema
import { donationSchema } from "forms/validations/donation";

import { giftingSchema } from "forms/validations/gifting";

// data
import { amountSelect } from "forms/donationData";

// images
import donationImg from "assets/images/donations-giting/donations.png";

import giftingImg from "assets/images/donations-giting/gifting.png";

import logo from "assets/images/logo/forest-logo.png";

import config from "assets/config";
import CurrencyService from "services/currency/currency.service";

import { ceil } from "lodash";
export default function DonationsGifting({ location }) {
  const [tabKey, setTabKey] = useState(
    queryString.parse(location.search).tabName
  );

  const [amountObj, setAmountObj] = useState([]);
  const [baseAmountValue, setBaseAmountValue] = useState([]);

  const initBaseAmount = async () => {
    const cs = new CurrencyService();
    const csResponse = await cs.getINR();
    csResponse && csResponse.data && csResponse.data.rates;
    const currencyList = csResponse.data.rates;
    const ds = new DonationService();
    const responseData = await ds
      .getDonorSaplingCount("FBH")
      .catch(function (error) {
        if (error.request) {
          alert("Somthing went wrong");
        }
      });

    let baseAmount;

    if (responseData && responseData.data) {
      if (responseData.data.saplingPrice !== "") {
        baseAmount = responseData.data.saplingPrice;
      } else {
        baseAmount = config.saplingsPrice;
      }

      setBaseAmountValue(baseAmount);

      const baseAmountConversion = amountSelect.map((item) => {
        item.amount.INR = item.plantCount * baseAmount;
        const rate = 0;
        Object.keys(currencyList).map((currencyType) => {
          const rate = ceil(
            (item.plantCount * baseAmount) / currencyList[currencyType]
          );

          if (rate > 0) {
            item.amount[currencyType] = rate;
          }
        });
        // if(currencyList['USD']) {
        // const rate = ceil((item.plantCount * baseAmount)/currencyList['USD']);
        // }
        // if(rate>0) {
        // item.amount.USD = rate;
        // }
        if (item.type === "input") {
          item.amount.INR = "";
          item.amount.USD = "";
        }
        return item;
      });

      setAmountObj(baseAmountConversion);
    } else {
      setAmountObj(amountSelect);
    }
  };

  useEffect(() => {
    initBaseAmount();
  }, []);

  return (
    <div className="donation-gifting-form-wrapper">
      <SEO title="Forests By Heartfulness | donation&gifting" />
      <Layout wrapperClass="dg-wrapper">
        <div className="container-fluid p-0">
          <div className="img-form-row row g-0">
            <div className="img-wrapper col-lg-5 col-md-12">
              {tabKey === "Donation" ? (
                <img src={donationImg} alt="donationImg" className="box-img" />
              ) : (
                <img src={giftingImg} alt="giftingImg" className="box-img" />
              )}
              <div
                className={`content ${
                  tabKey === "Donation" ? "donation" : "gifting"
                }`}
              >
                <div className="logo">
                  <img src={logo} alt="logo" />
                </div>
                <div className="info">
                  <div className="bth-link">
                    <i className="icon icon-arrow-left-short"></i>
                    <Link to="/">Back to Home</Link>
                  </div>
                  <h3>We are planting millions of trees across India</h3>
                </div>
              </div>
            </div>
            <div className="donation-gifting-wrapper col-lg-7 col-md-12">
              <Tabs
                defaultActiveKey="Donation"
                className="mb-3"
                activeKey={tabKey}
                onSelect={(k) => setTabKey(k)}
              >
                <Tab eventKey="Donation" title="Donation">
                  <DonationGiftingForm
                    formType="donation"
                    validationSchema={donationSchema}
                    amountSelect={amountObj}
                    baseAmountValue={baseAmountValue}
                  />
                </Tab>
                <Tab eventKey="Gifting" title="Gifting">
                  <DonationGiftingForm
                    formType="gifting"
                    validationSchema={giftingSchema}
                    amountSelect={amountObj}
                    baseAmountValue={baseAmountValue}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
